<template>
  <div class="contNewsList wrapper">
    <div class="box" v-for="item in newsList" :key="item.id">
      <img :src="item.coverUrl" class="left" style="cursor:pointer"
        @click="goTo(item.id)" />
      <div class="center">
        <div class="title" @click="goTo(item.id)">
          {{ item.title }}
        </div>
        <div class="detail" style="cursor:pointer" @click="goTo(item.id)">
          {{ item.summary }}
        </div>
      </div>
      <div class="right">
        <div class="day">
          {{ item.publishDate && item.publishDate.slice(8, 10) }}
        </div>
        <div class="month">
          {{ item.publishDate && item.publishDate.slice(0, 7) }}
        </div>
      </div>
    </div>
    <div class="pagination" v-if="newsList.length">
      <el-pagination @current-change="handleCurrentChange" :current-page="paramsInfo.pageNum" background
        layout="prev, pager, next" :total="total"></el-pagination>
    </div>
  </div>
</template>
<script>
import { newsPage, allNews } from "@/api/news.js";
export default {
  metaInfo: {
      title: '云窗科技', // set a title
      meta: [{                 // set meta
        name: '云窗科技、工会、智慧工会、工会平台、工会系统、职工服务平台、工会管理系统、工会管理平台、高校工会、高校工会平台、工会信息化建设、普惠服务、福利发放、提案系统、教代会平台、企业工会、集团工会',
        content: '云窗科技、工会、智慧工会、工会平台、工会系统、职工服务平台、工会管理系统、工会管理平台、高校工会、高校工会平台、工会信息化建设、普惠服务、福利发放、提案系统、教代会平台、企业工会、集团工会'
      }],
      link: [{                 // set link
        rel: 'asstes',
        href: 'http://yunchuangtech.com/'
      }]
   },
  name: "news",
  data() {
    return {
      total: 10,
      newsList: [],
      paramsInfo: {
        pageSize: 20,
        pageNum: 1
      }
    };
  },
  watch: {
    "$route.query.categoryId"(newVal, oldVal) {
      if (newVal == oldVal) return;
      this.getData();
    }
  },
  methods: {
    async getData() {
      let params = {
        pageSize: this.paramsInfo.pageSize,
        pageNum: this.paramsInfo.pageNum,
        categoryId: this.$route.query.categoryId
      };
      let res = {};
      if (this.$route.query.categoryId == 1) {
        params.type = params.categoryId;
        Reflect.deleteProperty(params, "categoryId");
        let { data: data } = await allNews(params);
        res = data;
      } else {
        let { data: data } = await newsPage(params);
        res = data;
      }
      this.total = res.data.total;
      this.newsList = res.data.records;
    },
    handleCurrentChange(newPage) {
      this.paramsInfo.pageNum = newPage;
      this.getData();
    },
    //跳转到详情页
    goTo(id) {
      this.$router.push({
        path: "/news/newsDetail",
        query: { id, categoryId: this.$route.query.categoryId }
      });
    }
  },
  created() {
    this.getData();
    if (this.$route.query && this.$route.query.id) {
      this.id = Number(this.$route.query.id);
    }
  }
};
</script>
<style lang="scss" scoped>

</style>
